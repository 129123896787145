import React,{useState, useRef, useEffect} from "react";
import { Link,useNavigate } from 'react-router-dom';
// import logo from '../icons/API.png'
import Spinner from '../tabs/Loader'
import Axios from 'axios' //used for API requests

//used for adding OpenStreetMap using Leaflet and Maptiler
import {MapContainer, Marker,TileLayer, Tooltip,LayerGroup,LayersControl,ZoomControl,Polyline,Polygon} from 'react-leaflet'
import L from 'leaflet'
import osm from "../osm-providers";
import 'leaflet/dist/leaflet.css'

import firebase from "../firebase"; //used for firebase

import "../App.css";
import LoggedIn_Header from "./LoggedIn_Header";

//import of markers
const boat_green = new L.Icon({
  iconUrl:require('../icons/boat_green.png'),
  iconSize:[40,40],
  // iconAnchor:[20,5]
})
const boat_red = new L.Icon({
  iconUrl:require('../icons/boat_red.png'),
  iconSize:[40,40],
  // iconAnchor:[20,5]
})
const vehicle_green = new L.Icon({
  iconUrl:require('../icons/vehicle_green.png'),
  iconSize:[40,40],
  // iconAnchor:[20,5]
})
const vehicle_red = new L.Icon({
  iconUrl:require('../icons/vehicle_red.png'),
  iconSize:[40,40],
  // iconAnchor:[20,5]
})
const people_red = new L.Icon({
  iconUrl:require('../icons/people_red.png'),
  iconSize:[40,40],
  // iconAnchor:[20,5]
})
const people_green = new L.Icon({
  iconUrl:require('../icons/people_green.png'),
  iconSize:[40,40],
  // iconAnchor:[20,5]
})
const lg_tower = new L.Icon({
  iconUrl:require('../icons/lg_tower.png'),
  iconSize:[40,40],
  // iconAnchor:[20,5]
})
const office = new L.Icon({
  iconUrl:require('../icons/office.png'),
  iconSize:[40,40],
  // iconAnchor:[20,5]
})
const general = new L.Icon({
  iconUrl:require('../icons/general.png'),
  iconSize:[40,40],
  // iconAnchor:[20,5]
})

const center = [15.3790367, 73.9258356]
const rectangle = [
  [51.49, -0.08],
  [51.5, -0.06],
]

//random color for grid lines
function randomColor() {
  return `#${Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, 0)}`;
}
function All() {
  let navigate = useNavigate()
  let path='/'
  //moment is used to fetch date and work with formatting of dates as per user requirement
  var moment = require('moment');
  var current_date = moment().utcOffset('+05:30').format('ddd MMM DD');

  //center is used for setting the center point of the map and zoom level is used for zooming the map
  const [center, setCenter]=useState({lat:15.3790367,lng:73.9258356})
  const ZOOM_LEVEL = 10;
  const mapRef = useRef()

  const [vehicle_data,setVehicle]=useState([]) //used to store vehicle data
  const [res,setRes]=useState('') //used for storing response coming from API
  const [isLoading,setloading]=useState(false) //used for loading till the API data is fetched
  const [check_all,setCheck_all] = useState(true)
  // const [check_user,setCheck_user]=useState(true) //used for the displaying of user labels on map
  // const [check_vehicle,setCheck_vehicle]=useState(true) //used for the displaying of vehicle labels on map
  // const [check_boats,setCheck_boats]=useState(true) //used for the displaying of boats labels on map
  // const [check_point,setCheck_point]=useState(true) //used for the displaying of boats labels on map
  // const [check_grid,setCheck_grid]=useState(true) //used for the displaying of boats labels on map
  // const [check_triton,setCheck_triton]=useState(true)
  var vehicle_count = 0 //used for storing count of active vehicles
  var boats_count = 0 //used for storing count of active boats

  const [user, setUser] = useState([]) //used to store active users
  const [offline, setOffline_user] = useState([])//used to store inactive users
  
  const [mainGrid,setMainGrid]=useState([]) //store grids in array
  const [loading,setLoading]=useState(true)
  const [gridData,setGridData] = useState([]) //store grid names in array
  const [gridType,setGridType] = useState([])
  const [userLoading,setUserLoading] = useState(true)

  const [points,setPoints] = useState([]) //store grid points in array

  const [triton,setTriton] = useState([])
  const [online_triton,setOnline_Triton] = useState([])
  const [triton_loading,setTritonLoading] = useState(true)

  useEffect(() => {
    const Triton=()=>{
      online_triton.length=0
      triton.length=0
      setTriton([])
      setOnline_Triton([])
  
      const ref = firebase.app('secondary1').firestore().collection('triton')
      ref.get().then((snapshot)=>{
        snapshot.docs.forEach(doc=>{
          triton.push(doc.data())
          console.log(doc.data())
          if(doc.data().camera_status==='online'){
            online_triton.push(doc.data())
          }
        })
        setTriton(triton)
        setOnline_Triton(online_triton)
        setLoading(false)
    }).catch(function(error){
      navigate(path)

     })
    }
    Triton()

    const point =()=>{
     points.length=0
      setPoints([])

      //Fetch grid points data from firestore 
     setLoading(true)
      const ref = firebase.app('secondary').firestore().collection('Marker1')
      ref.get().then((snapshot)=>{
       var len =snapshot.docs.length
       snapshot.docs.forEach((doc,i)=>{
         const ref1= ref.doc(doc.id).collection("Points")
         ref1.get().then((snapshot)=>{          
          snapshot.docs.forEach((doc)=>{         
           points.push(doc.data())
          })
          if(i===len-1){
           setPoints(points)
           setLoading(false)
          }         
        }) 
       })             
   }).catch(function(error){
    navigate(path)

   })
   }
   point()



    const grid =()=>{
      mainGrid.length=0
      gridData.length=0
      gridType.length=0
      setGridType([])
      setGridData([])
      setMainGrid([]) 
      var index=-1
      var index1=-1

// console.log(mainGrid)
         //Fetch grid data from firestore

         const ref = firebase.app('secondary').firestore().collection('Grid')
         ref.get().then((snapshot)=>{
          var len=snapshot.docs.length;
          snapshot.docs.forEach((doc,l)=>{
            const ref1= ref.doc(doc.id).collection("Location1")
            ref1.get().then((snapshot)=>{
              var len1=snapshot.docs.length;
             snapshot.docs.forEach((doc,i)=>{
               index1++;
              //  console.log(index1)
               const ref2=ref1.doc(doc.id).collection("subPath")
               ref2.get().then((snapshot)=>{
                var len2=snapshot.docs.length;
                 snapshot.docs.forEach((doc,j)=>{
                  mainGrid.push([])
                  gridData.push([doc.id])
                  gridType.push(doc.data().type)
                   const ref3=ref2.doc(doc.id).collection("coordinates")
                   ref3.get().then((snapshot)=>{
                    index++
                     snapshot.docs.forEach((doc,k)=>{                   
                       mainGrid[index].push([doc.data().latitude,doc.data().longitude])
                     })               
                     if(l===len-1 && i===len1-1 && j===len2-1){
                     
                       setGridType(gridType)
                       setGridData(gridData)
                       setMainGrid(mainGrid) 
                        setLoading(false)

                        console.log(gridType)
                     }
                   })              
                 })                      
               })
             })
           })     
          })                    
      }).catch(function(error){
        navigate(path)

       })
}

      grid()
      

//Fetch data from firestore and store them in the user and offline array it is used for displaying active and inactive users

      user.length=0
      offline.length=0
      setUser([])
      setOffline_user([])
      setUserLoading(true)
     const subscriber = firebase.firestore().collection('User').get().then((snapshot)=>{
       
       snapshot.docs.forEach(doc=>{
        var date = doc.get('Last location time')
        if(date!==undefined){
          if(date!==null){
            if(date.seconds!==undefined){
              var time_date = date.toDate(date)
              var abc = time_date.toString()
              var sliced = abc.slice(0,10)
              var time = abc.slice(15,24)
            }
            else{          
              var sliced = date.slice(0,10)
              var time = date.slice(10,20)
            }
          }
          
        }
         
        if(doc.get('Current status')===true ){
          if(doc.get('Last location')!==null){
            if(sliced===current_date){
              user.push({
                key: doc.data().Id,
                location:doc.get("Last location"),
                name:doc.data().Name.toUpperCase(),
                status:doc.get('Current status'),
                email:doc.get('Email'),
                date:sliced,
                time:time
           })
            }
          }  
        }
        if(doc.get('Current status')===false ){
          if(doc.get('Last location')!==null){
           if(sliced===current_date){
             offline.push({
               key: doc.data().Id,
               location:doc.get("Last location"),
               name:doc.data().Name.toUpperCase(),
               status:doc.get('Current status'),
               email:doc.get('Email'),
               date:sliced,
               time:time
          })  
           }
          }    
        }
            
     })
     setOffline_user(offline)
     setUser(user)
     setUserLoading(false)
  
     user.map((people) =>people.name==="ARYAN JAIN"?console.log(people.status) :null )
     offline.map((people) =>people.name==="ARYAN JAIN"?console.log(people.status) :null )
  }).catch(function(error){
    navigate(path)

   })


      //fetching data from API and storing it in array
    const vehicle =()=>{
      Axios.get('https://aditigps.com/webservice?token=getLiveData&user=drishti&pass=Tower&company=Drishti lifesaving&format=json').then((response)=>{
        setRes(response.data.root.error)
        if(response.data.root.error!==undefined){
          // console.log("All",response)
          setloading(false)
        }
        else{
          // console.log("All",response)
          setVehicle(response.data.root.VehicleData)
          setloading(false)
        }
      
      })
    }
    vehicle()

//fetching data and storing it in array after every 2 minutes
     var handle = setInterval(()=>{
      const Triton=()=>{
        online_triton.length=0
        triton.length=0
        setTriton([])
        setOnline_Triton([])
    
        const ref = firebase.app('secondary1').firestore().collection('triton')
        ref.get().then((snapshot)=>{
          snapshot.docs.forEach(doc=>{
            triton.push(doc.data())
            console.log(doc.data())
            if(doc.data().camera_status==='online'){
              online_triton.push(doc.data())
            }
          })
          setTriton(triton)
          setOnline_Triton(online_triton)
          setLoading(false)
      }).catch(function(error){
        navigate(path)

       })
      }
      Triton()

      const point =()=>{
        points.length=0
        setPoints([])
        //Fetch grid points data from firestore 
       setLoading(true)
        const ref = firebase.app('secondary').firestore().collection('Marker1')
        ref.get().then((snapshot)=>{
         var len =snapshot.docs.length
         snapshot.docs.forEach((doc,i)=>{

           const ref1= ref.doc(doc.id).collection("Points")
           ref1.get().then((snapshot)=>{
             
            snapshot.docs.forEach((doc)=>{
             
             points.push(doc.data())
  
            })

            if(i===len-1){
             setPoints(points)
             setLoading(false)
            }
            
          })
    
         })
         
         
         
     }).catch(function(error){
      navigate(path)

     })
     
  
     }
  
     point()
  
      const grid =()=>{
        mainGrid.length=0
        gridData.length=0
        gridType.length=0
        setGridType([])
        setGridData([])
        setMainGrid([]) 
        var index=-1
        var index1=-1
  
           //Fetch grid data from firestore
           const ref = firebase.app('secondary').firestore().collection('Grid')
           ref.get().then((snapshot)=>{
            var len=snapshot.docs.length;
            snapshot.docs.forEach((doc,l)=>{
              const ref1= ref.doc(doc.id).collection("Location1")
              ref1.get().then((snapshot)=>{
                var len1=snapshot.docs.length;
               snapshot.docs.forEach((doc,i)=>{
                 index1++;
                //  console.log(index1)
                 const ref2=ref1.doc(doc.id).collection("subPath")
                 ref2.get().then((snapshot)=>{
                  var len2=snapshot.docs.length;
                   snapshot.docs.forEach((doc,j)=>{
                    mainGrid.push([])
                    gridData.push([doc.id])
                    gridType.push(doc.data().type)
                     const ref3=ref2.doc(doc.id).collection("coordinates")
                    
                     ref3.get().then((snapshot)=>{
                      index++
                       snapshot.docs.forEach((doc,k)=>{
                     
                         mainGrid[index].push([doc.data().latitude,doc.data().longitude])
                       })
                  
                       if(l===len-1 && i===len1-1 && j===len2-1){
                        // console.log(l,"---",len)
                        setGridType(gridType)
                         setGridData(gridData)
                         setMainGrid(mainGrid) 
                        //  console.log(mainGrid)
                          setLoading(false)
                       }
                     })
                
                   })
                   
       
                 })
               })
             })
       
            })
            
            
            
        }).catch(function(error){
          navigate(path)

         })
        

  
        }
  
        grid()
        

        user.length=0
        offline.length=0
        
        setUser([])
        setOffline_user([])
        setUserLoading(true)
        
    
           //Fetch data from firestore and store them in the num array
       const subscriber = firebase.firestore().collection('User').get().then((snapshot)=>{
         
         snapshot.docs.forEach(doc=>{
          var date = doc.get('Last location time')
          if(date!==undefined){
            if(date!==null){
              if(date.seconds!==undefined){
                var time_date = date.toDate(date)
                var abc = time_date.toString()
                var sliced = abc.slice(0,10)
                var time = abc.slice(15,24)
                
              }
              else{          
                var sliced = date.slice(0,10)
                var time = date.slice(10,20)
                // console.log(time)
              }
            }
            
          }
          if(doc.get('Current status')===true ){
            if(doc.get('Last location')!==null){
              if(sliced===current_date){
                user.push({
                  key: doc.data().Id,
                  location:doc.get("Last location"),
                  name:doc.data().Name.toUpperCase(),
                  status:doc.get('Current status'),
                  email:doc.get('Email'),
                  date:sliced,
                  time:time
       
             })  
              }
            }
          }
          if(doc.get('Current status')===false ){
            if(doc.get('Last location')!==null){
              if(sliced===current_date){
               offline.push({
                 key: doc.data().Id,
                 location:doc.get("Last location"),
                 name:doc.data().Name.toUpperCase(),
                 status:doc.get('Current status'),
                 email:doc.get('Email'),
                 date:sliced,
                 time:time
            })  
              }
            }   
          }
              
       })
       setOffline_user(offline)
       setUser(user)
       setUserLoading(false)
       
       user.map((people) =>people.name==="ARYAN JAIN"?console.log(people.time) :null )
       offline.map((people) =>people.name==="ARYAN JAIN"?console.log(people.time) :null )
    }).catch(function(error){
      navigate(path)

     })


      const vehicle =()=>{
        Axios.get('https://aditigps.com/webservice?token=getLiveData&user=drishti&pass=Tower&company=Drishti lifesaving&format=json').then((response)=>{
          setRes(response.data.root.error)
          if(response.data.root.error!==undefined){
            // console.log("All",response)  
          }
          else{
            // console.log("All",response)
            setVehicle(response.data.root.VehicleData)
          }
        })
      }
      vehicle()
     },121000)

     return()=>{
       clearInterval(handle)
       
       grid()
       point()
       Triton()
       
       console.log("Unsubscribe all firestore")
       return()=>subscriber()
     }
 }, []);



  return (
    <div className="container">
      <LoggedIn_Header/>
      <div className="bloc-tabs">
        <button className="tabs active-tabs" style={{fontSize:18,fontFamily:'sans-serif'}}>ALL</button>
        <Link to="/vehicle" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>VEHICLE</Link>
        <Link to="/boats" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>BOATS</Link>
        <Link to="/people" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>PEOPLE</Link>
        <Link to="/triton" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>TRITON</Link>
        <Link to="/aurus" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>AURUS</Link>
        
        
      </div>

      <div className="content-tabs">
      
        <div className="content"><h2 style={{fontFamily:'Roboto'}}>Vehicle Heading</h2><hr /></div>
        <div className="content"><h2 style={{fontFamily:'Roboto'}}>Boat Heading</h2><hr /></div>
        <div className="content"><h2 style={{fontFamily:'Roboto'}}>People Heading</h2><hr /></div>
        <div className="content"><h2 style={{fontFamily:'Roboto'}}>Triton Heading</h2><hr /></div>
        <div className="content"><h2 style={{fontFamily:'Roboto'}}>Aurus Heading</h2><hr /></div>

        {isLoading===false?res===undefined?<div className="content  active-content">

        {vehicle_data.map((veh)=>{
            if(veh.Vehicletype!=="Sailboat"){
              if(veh.Status!=="INACTIVE"){
                vehicle_count=vehicle_count+1
              }
            }
            else{
              if(veh.Status!=="INACTIVE"){
                boats_count=boats_count+1
              }
            } 
          })}
          {/* <div style={{flexDirection:'row',display:'flex',justifyContent:'space-evenly'}}>
          <h3 style={{fontFamily:'Roboto',}}>Active Users: {user.length}</h3>
          <h3 style={{fontFamily:'Roboto'}}>Active Vehicles: {vehicle_count}</h3>
          <h3 style={{fontFamily:'Roboto',marginRight:'10px'}}>Active Boats: {boats_count}</h3>
          </div> */}
          
          <div style={{flexDirection:'row',display:'flex',justifyContent:'flex-start'}}>
            <div style={{flexDirection:'row',display:'flex',justifyContent:"normal"}}>
              <h3 style={{fontFamily:'Roboto'}}>Show label</h3>
              <input type="checkbox" defaultChecked  onChange={(e)=>{setCheck_all(e.target.checked)}} style={{marginTop:'6px',marginLeft:'6px'}} />
            </div>
          </div>

          <hr />

          <div style={{minHeight:'80vh'}}>
            <MapContainer
                center={center}
                zoom={ZOOM_LEVEL}
                ref={mapRef}
                zoomControl={false}
                style={{height:'73vh'}}
              >
                <TileLayer url={osm.maptiler.url} attribution={osm.maptiler.attribution}/>
                {userLoading===true?<div>Loading...</div>:
                <>
                <ZoomControl position="topright"/>
                  <LayersControl position="topleft" collapsed={false} >
                    <LayersControl.Overlay checked name={"Triton Poles "+"("+(online_triton.length+1)+")"}>
                      <LayerGroup>
                        {triton.map((triton,idx) =><Marker position={[triton.location._lat,triton.location._long]} icon={triton.camera_status==='offline'?people_red:people_green} key={idx} >   
                        {check_all?<Tooltip direction="top" permanent='true'>{triton.name}</Tooltip>:null}</Marker> )}
                      </LayerGroup>
                    </LayersControl.Overlay>
          
                    <LayersControl.Overlay checked name="Grid">
                      <LayerGroup>
                        {/* //To give color to Polygon based on the Grid type use a ternary operator (condition?true_value:false_value) 
                          Note: the name should match the name stored in firebase */}

                        {mainGrid.map((main,idx)=><Polygon positions={main} key={idx} color={gridType[idx]==='beach'?'yellow':gridType[idx]==='office'?'blue':'green'} >
                        {check_all?<Tooltip permanent="true" position={main[0]} direction="top">{gridData[idx]}</Tooltip>:null}</Polygon>)}
                      </LayerGroup>
                    </LayersControl.Overlay>

                    <LayersControl.Overlay checked name="Grid Points">
                      <LayerGroup>
                        {/* //To give icons to grid points based on the marker type use a ternary operator (condition?true_value:false_value)
                            add the icon in the icons folder and import the icon on the top of the code.  
                            Note: the name should match the name stored in firebase */}
                        {points.map((point,idx) => <Marker  position={[point.latitude,point.longitude]} icon={point.type==="LG Tower"?lg_tower:point.type==="Office"?office:general} key={idx} >
                        {check_all?<Tooltip direction="top" permanent="true">{point.name}</Tooltip>:null}</Marker>)}
                      </LayerGroup>
                    </LayersControl.Overlay>

                    <LayersControl.Overlay checked name={"Vehicle "+"("+vehicle_count+")"}>
                      <LayerGroup>
                        {vehicle_data.map((city,idx) => city.Vehicletype!=="Sailboat"?<Marker position={[city.Latitude,city.Longitude]} icon={city.Status==="INACTIVE"?vehicle_red:vehicle_green} key={idx}>
                        {check_all?<Tooltip direction="top" permanent="true">{city.Vehicle_No+" / Speed: "+city.Speed}</Tooltip>:null}</Marker>:null)}
                      </LayerGroup>
                    </LayersControl.Overlay>

                    <LayersControl.Overlay checked name={"People "+"("+user.length+")"}>
                      <LayerGroup>
                        {user.map((people,idx) => <Marker eventHandlers={{click:(e)=>{console.log("Clicked",(people.name))}}} position={[people.location._lat,people.location._long]} icon={people_green} key={idx} >
                        {check_all?<Tooltip direction='top' permanent="true">{people.name+" / Time: "+people.time}</Tooltip>:null}</Marker> )}
                        {offline.map((people,idx) => <Marker  position={[people.location._lat,people.location._long]} icon={people_red} key={idx} >
                        {check_all?<Tooltip direction="top" permanent="true">{people.name+" / Time: "+people.time}</Tooltip>:null}</Marker> )}
                      </LayerGroup>
                    </LayersControl.Overlay>

                    <LayersControl.Overlay checked name={"Boats "+"("+boats_count+")"}>
                      <LayerGroup>
                        {vehicle_data.map((city,idx) => city.Vehicletype==="Sailboat"?<Marker position={[city.Latitude,city.Longitude]} icon={city.Status==="INACTIVE"?boat_red:boat_green} key={idx}>
                        {check_all?<Tooltip direction="top" permanent="true">{city.Vehicle_No+" / Speed: "+city.Speed}</Tooltip>:null}</Marker>:null)}
                      </LayerGroup>
                    </LayersControl.Overlay>

                  </LayersControl></>}
            
            </MapContainer>

          </div>
        </div> :<div className="content  active-content"> <Spinner/></div>:<div className="content  active-content"><h2 style={{fontFamily:'Roboto'}}>Loading....</h2></div>}
      </div>
    </div>
  );
}

export default All;