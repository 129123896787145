// import React from "react"; 
// import "../App.css";
// import Header from '../tabs/Header'
// import Screen from '../icons/Atlas_screen.png'

// function Login() {

  
//   return (
//     <div className="container">
//       <div className="content-tabs">
//       <Header/>
//       <img src={Screen} style={{width:'100%'}}/>  
//       </div>
//       <div className="content  active-content"><hr></hr></div>
//     </div>
//   );
// }

// export default Login;
import React from "react"; 
import "../App.css";
import Header from '../tabs/Header'
import Screen from '../icons/Atlas_screen.png'

function Login() {

  
  return (
    <div className="container">
      <div className="content-tabs">
        <Header/>
          <img src={Screen} style={{width:'100%'}}/> 
          <div className="content  active-content"> </div>
      </div>
    </div>
  );
}

export default Login;