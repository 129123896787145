import React,{useState, useRef, useEffect} from "react"; 
import { Link,useNavigate } from 'react-router-dom';

//used for adding OpenStreetMap using Leaflet and Maptiler
import {MapContainer, Marker,TileLayer, Tooltip,Polyline} from 'react-leaflet'
import L from 'leaflet'
import osm from "../osm-providers";
import 'leaflet/dist/leaflet.css'

import firebase from "../firebase"; //calling firebase to the component

import "../App.css";
import LoggedIn_Header from "./LoggedIn_Header";
import Popup from "../components/Popup/Popup";

import SearchBar from '../components/SearchBar/SearchBar'
import "../components/SearchBar/SearchBar.css";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close"

import DateMomentUtils from '@date-io/moment'
import { KeyboardDatePicker,KeyboardTimePicker,MuiPickersUtilsProvider} from "@material-ui/pickers"; //used for selecting date and time


//import of markers
//if you need to change the icon in future change the iconUrl and add the image in the icons folder current size of image is 40*40 px

const people_red = new L.Icon({
  iconUrl:require('../icons/people_red.png'),
  iconSize:[40,40],
  iconAnchor:[20,5],
})

const people_green = new L.Icon({
  iconUrl:require('../icons/people_green.png'),
  iconSize:[40,40],
  iconAnchor:[20,5],
})



function People() {
  let navigate = useNavigate()
  let path='/'
  //moment is used to fetch date and work with formatting of dates as per user requirement
  var moment = require('moment');
  var current_date = moment().utcOffset('+05:30').format('ddd MMM DD');

  //center is used for setting the center point of the map and zoom level is used for zooming the map
  const [center, setCenter]=useState({lat: 18.9713022, lng: 72.8435152})
  const[ZOOM_LEVEL,setZoom]  = useState(10);
  

  const mapRef = useRef()

  const [user, setUser] = useState([]); //used to store active users
  const [offline, setOffline_user] = useState([]); //used to store inactive users
  const [check,setCheck]=useState(true) //used for the displaying of labels on map

  const [isOpen,setIsOpen]=useState(false) //used for displaying the Popup
  const [popup_title,setTitle] = useState('') //used for displaying the user name in popup
  const [email,setEmail] = useState(null) //used for setting email of a selected user

  const [date,setDate]=useState(null) //used for setting the date for polyline
  const [start_time,setStartTime]= useState(null) //used for setting the start time for polyline
  const [end_time,setEndTime]= useState(null) //used for setting the end time for polyline

  const [poly,setPolyline]=useState([]) //used to store polyline values
  
  const [showMap,setShowMap]=useState(false)  //used to display the Map with polylines
  const [button,setButton]=useState(false) //used to toggle between the date time and showmap
  const [display,setDisplay]=useState(false) //used for displaying if there is no playback

  const [loading,setLoading] = useState(true)
  const [pointer,setPointer] = useState([])
  const [search, setSearch] = useState([]);

  const [filteredData, setFilteredData] = useState([]); //setting the data of users for the dropdown component
  const [wordEntered, setWordEntered] = useState(""); //storing the name selected from the dropdown

  //used for handling the name selection in dropdown
  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    const newFilter = search.filter((value) => {
      return value.name.toLowerCase().includes(searchWord.toLowerCase());
    });

    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };

//Clearing the dropdown selection
  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
    setZoom(10);
    setCenter({lat: 18.9713022, lng: 72.8435152})

  };

  //used to send data to Polyline
  const togglePopup=(latitude,longitude,email,name)=>{
    //setCenter({lat: latitude, lng: longitude})
    setTitle(name)
    setIsOpen(true)
    setEmail(email)
  }
  //used to set state when the popup is closed
  const popupClose=()=>{
    setIsOpen(false)
    setDate(null)
    setStartTime(null)
    setEndTime(null)
    setEmail(null)
    setPolyline([])
    setShowMap(false)
    setButton(false)
    setCenter({lat: 18.9713022, lng: 72.8435152})
    setZoom(10)
    setWordEntered('')
    setDisplay(false)
    setLoading(true)
    setPointer([])
  }

  //used for checking if all the fields are filled and fetch data from firestore and store data into polyline
  const getPolyline=()=>{
    if(date === null){
      alert("Enter Date")
    }
    else{
      if(start_time===null){
        alert("Enter Start Time")
      }
      else{
        if(end_time===null){
          alert("Enter End Time")
        }else{
          if(end_time<=start_time){
            alert("End Time is smaller than Start Time")
          }
          else{
            
            var sliced_col=date.format('DD MMM YYYY')
            var start=date.format('ddd MMM DD ')+start_time.format('HH mm ss')+' GMT'+date.format('Z YYYY')
            var end = date.format('ddd MMM DD ')+end_time.format('HH mm ss')+' GMT'+date.format('Z YYYY')

            setLoading(true)

            var reference=firebase.firestore().collection("User").doc(email).collection(sliced_col)
            reference.get().then((snapshot)=>{
              
              snapshot.docs.forEach(doc=>{
                reference.doc(doc.id).collection('Location logs').orderBy('Time stamp', 'asc').get().then((snapshot)=>{
              
                  snapshot.docs.forEach(doc=>{
                     var time = doc.get('Time stamp').toString()
                     var sliced_time = time.slice(11,20)
                     
                     //console.log('Marker',sliced_time)
                     var location = doc.get('Location stamp')
                    if(time>=start && time<=end){
                      poly.push([
                        location._lat,
                        location._long,
                        sliced_time]
                      
                      )
                     }
                     
                  
                })
                setLoading(false)
             })
            })
         }).catch(function(error){
          navigate(path)

         })

         setPolyline(poly)
         setButton(true)
         console.log("Polyline",poly)
  
           
          }

        }
        
      }
    }
  }

//used to display the map depending upon the polyline data
  const getMap=()=>{
    if(poly.length===0){
      setDisplay(true)
    }else{
    setCenter({lat: poly[0][0], lng: poly[0][1]})
    setShowMap(true)
    var j = 0;
    pointer.push(poly[0])
    for(var i=1;i<poly.length;i++){
      var time1=poly[j][2].split(":");
      var time2=poly[i][2].split(":");
      var diff=time2[2]-time1[2]+ (time2[0]-time1[0])*3600+(time2[1]-time1[1])*60
      if(diff>=1800){
        j=i
        pointer.push(poly[i])
      }
    }
    pointer.push(poly[poly.length-1])
    setPointer(pointer)
    }
    
  }

  
  useEffect(() => {

    user.length=0
    offline.length=0
    search.length=0
    setUser([])
    setOffline_user([])
    setSearch([])

  //Fetch data from firestore and store them in the user and offline array it is used for displaying active and inactive users
   const subscriber = firebase.firestore().collection('User').orderBy('Name','asc').get().then((snapshot)=>{
     
     snapshot.docs.forEach(doc=>{
        var date = doc.get('Last location time')
        if(date!==undefined){
          if(date!==null){
            if(date.seconds!==undefined){
              var time_date = date.toDate(date)
              var abc = time_date.toString()
              var sliced = abc.slice(0,10)
              
            }
            else{          
              var sliced = date.slice(0,10)
            }
          }
          
        }
        
      if(doc.get('Current status')===true ){
        if(doc.get('Last location')!==null){
          if(sliced===current_date){
            user.push({
              key: doc.data().Id,
              location:doc.get("Last location"),
              name:doc.data().Name.toUpperCase(),
              status:doc.get('Current status'),
              email:doc.get('Email'),
              date:sliced        
         })
          }
        }     
      }
      if(doc.get('Current status')===false ){
       if(doc.get('Last location')!==null){
        if(sliced===current_date){
          offline.push({
            key: doc.data().Id,
            location:doc.get("Last location"),
            name:doc.data().Name.toUpperCase(),
            status:doc.get('Current status'),
            email:doc.get('Email'),
            date:sliced
       })  
        }
       }    
     }      

     if(doc.get('Last location')!==null){
      if(sliced===current_date){
        search.push({
          key: doc.data().Id,
          location:doc.get("Last location"),
          name:doc.data().Name.toUpperCase(),
          status:doc.get('Current status'),
          email:doc.get('Email'),
          date:sliced       
     })
      }
    }
   })
   setOffline_user(offline)
   setUser(user)
   setSearch(search)
  console.log("Search",search)
}).catch(function(error){
  navigate(path)
})


    
 var handle = setInterval(()=>{
  
    user.length=0
    offline.length=0
    search.length=0
    setUser([])
    setOffline_user([])
    setSearch([])
    
  //Fetch data from firestore and store them in the user and offline array it is used for displaying active and inactive users
   const subscriber = firebase.firestore().collection('User').orderBy('Name','asc').get().then((snapshot)=>{
     
     snapshot.docs.forEach(doc=>{
      var date = doc.get('Last location time')
      if(date!==undefined){
        if(date!==null){
          if(date.seconds!==undefined){
            var time_date = date.toDate(date)
            var abc = time_date.toString()
            var sliced = abc.slice(0,10)    
          }
          else{          
            var sliced = date.slice(0,10)
          }
        }
      }
      if(doc.get('Current status')===true ){
        if(doc.get('Last location')!==null){
          if(sliced===current_date){
            user.push({
              key: doc.data().Id,
              location:doc.get("Last location"),
              name:doc.data().Name.toUpperCase(),
              status:doc.get('Current status'),
              email:doc.get('Email'),
              date:sliced
         })  
          }
        }
      }
      if(doc.get('Current status')===false ){
       if(doc.get('Last location')!==null){
         if(sliced===current_date){
          offline.push({
            key: doc.data().Id,
            location:doc.get("Last location"),
            name:doc.data().Name.toUpperCase(),
            status:doc.get('Current status'),
            email:doc.get('Email'),
            date:sliced
       })  
         }
       }   
     }         
     if(doc.get('Last location')!==null){
      if(sliced===current_date){
        search.push({
          key: doc.data().Id,
          location:doc.get("Last location"),
          name:doc.data().Name.toUpperCase(),
          status:doc.get('Current status'),
          email:doc.get('Email'),
          date:sliced
     })
      }
    }
   })
   setOffline_user(offline)
   setUser(user)
   setSearch(search)
   console.log("Search",search)
   
}).catch(function(error){
  
  navigate(path)
  
})

 },120000)

 return()=>{
  
   clearInterval(handle)
   return()=>subscriber()
   
 }

 }, []);

  return (
    <div className="container">
      <LoggedIn_Header/>
      <div className="bloc-tabs">
        <Link to="/all" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>ALL</Link>
        <Link to="/vehicle" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>VEHICLE</Link>
        <Link to="/boats" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>BOATS</Link>
        <button className="tabs active-tabs" style={{fontSize:18,fontFamily:'sans-serif'}}>PEOPLE</button>
        <Link to="/triton" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>TRITON</Link>
        <Link to="/aurus" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>AURUS</Link> 
      </div>

      <div className="content-tabs">
        <div className="content"><h2 style={{fontFamily:'Roboto'}}>Vehicle Heading</h2><hr /></div>
        <div className="content"><h2 style={{fontFamily:'Roboto'}}>Boat Heading</h2><hr /></div>

        <div className="content  active-content">
          
        {isOpen && <Popup handleClose={popupClose} 
        content={<div>
          <h2 style={{marginLeft:'1%'}}>{popup_title}</h2>
          {button===false?
          <div>
            <MuiPickersUtilsProvider utils={DateMomentUtils}>
              <KeyboardDatePicker value={date} onChange={setDate} placeholder="Select Date" clearable maxDate={new Date()} style={{marginLeft:'1%'}} />
              <KeyboardTimePicker value={start_time} onChange={setStartTime} placeholder="Select Start Time" clearable style={{marginLeft:'1%'}}/>
              <KeyboardTimePicker value={end_time} onChange={setEndTime} placeholder="Select End Time" clearable style={{marginLeft:'1%'}}/>
            </MuiPickersUtilsProvider>
            <button style={{marginLeft:'2%',marginTop:'0.5%',backgroundColor:'blue',height:'30px',padding:'4px',color:'#ffffff',cursor:'pointer'}} onClick={()=>getPolyline()}>SUBMIT</button>
          </div>:showMap===true ?null:loading===true?<h3 style={{marginLeft:'1%'}}>No Playback for Date: {date.format('DD MMM YYYY     ')}Time: {start_time.format('hh:mm A ')} to {end_time.format('hh:mm A ')}</h3>:<div><button style={{marginLeft:'1%',backgroundColor:'blue',height:'30px',padding:'4px',color:'#ffffff',cursor:'pointer'}} onClick={()=>getMap()}>Show Map</button></div>}
            
            {showMap===true?<div><h3 style={{marginLeft:'1%'}}>Date: {date.format('DD MMM YYYY     ')}Time: {start_time.format('hh:mm A ')} to {end_time.format('hh:mm A ')}</h3><MapContainer
              center={center}
              zoom={100}
              ref={mapRef}
            >
              
              <TileLayer url={osm.maptiler.url} attribution={osm.maptiler.attribution}/>
                <Polyline positions={poly} color="blue" />
                {pointer.map((people,idx) =><Marker position={[people[0],people[1]]} icon={people_red} key={idx}><Tooltip direction="top" permanent='true' style={{color:'blue'}}>{people[2]}</Tooltip></Marker> )}
            </MapContainer></div>:display===true?<h2 style={{marginLeft:'1%'}}>No Playback for Date: {date.format('DD MMM YYYY     ')}Time: {start_time.format('hh:mm A ')} to {end_time.format('hh:mm A ')}</h2>:null}
          
          
        </div>}
        />
        
       }
        
          <div style={{display:'flex', flexDirection:'row',justifyContent:'space-between'}}>
            <div style={{display:'flex',justifyContent:'normal',flexDirection:'column'}}>
              <h3 style={{fontFamily:'Roboto'}}>Active Users: {user.length}</h3>
            <div style={{display:'flex', flexDirection:'row'}}>
              <h3 style={{fontFamily:'Roboto'}}>Show User label &nbsp;&nbsp;</h3>
              <input type="checkbox" defaultChecked onChange={(e)=>setCheck(e.target.checked)} style={{marginTop:'8px'}} />
            </div>
            </div>
          
            {isOpen===false?<SearchBar searchbar_content={<><div className="searchInputs" >
              <input
                type="text"
                placeholder="Search User"
                value={wordEntered}
                onChange={handleFilter}
              />
              <div className="searchIcon">
                {wordEntered.length === 0 ? (
                  <SearchIcon />
                ) : (
                  <CloseIcon id="clearBtn" onClick={clearInput} />
                )}
              </div>
              </div>
              {filteredData.length != 0 && (
                <div className="dataResult">
                  {filteredData.slice(0, 30).map((value, key) => {
                    return (
                      <a className="dataItem" 
                            onClick={(e) =>{
                                setWordEntered('')
                                setWordEntered(value.name)
                                setFilteredData([])
                                setCenter({lat: value.location._lat, lng: value.location._long})
                                setZoom(100)
                            }
                            }>
                        <p>{value.name}</p>
                      </a>
                    );
                  })}
                </div>
              )}</>} />:null}
            
          </div>
          
          <hr />
          
          {isOpen===false && filteredData.length===0?<div>
          <MapContainer
            center={center}
            zoom={ZOOM_LEVEL}
            ref={mapRef}
            style={{height:'73vh'}}
          >
            <TileLayer url={osm.maptiler.url} attribution={osm.maptiler.attribution}/>
            {user.map((people,idx) =>people.date===current_date? <Marker position={[people.location._lat,people.location._long]} icon={people_green} key={idx} eventHandlers={{click:()=>{togglePopup(people.location._lat,people.location._long,people.email,people.name)}}} >   
            {check?<Tooltip direction='top' permanent='true'><h4 style={{backgroundColor:'#004AAD',color:'white',height:'20px',margin:'-8px',textAlign:'center',paddingRight:'6px',paddingLeft:'6px',paddingTop:'2px',paddingBottom:'2px'}}>{people.name}</h4></Tooltip>:null}</Marker>:null )}

            {offline.map((people,idx) =>people.date===current_date? <Marker position={[people.location._lat,people.location._long]} icon={people_red} key={idx} eventHandlers={{click:()=>{togglePopup(people.location._lat,people.location._long,people.email,people.name)}}} > 
              {check?<Tooltip direction="right" permanent='true'>{people.name}</Tooltip>:null}</Marker>:null )}
          </MapContainer>
        </div>:null}
          
        </div>

        <div className="content"><h2 style={{fontFamily:'Roboto'}}>Triton Heading</h2><hr /></div>
        <div className="content"><h2 style={{fontFamily:'Roboto'}}>Aurus Heading</h2><hr /></div>
        <div className="content"><h2 style={{fontFamily:'Roboto'}}>All Heading</h2><hr /></div>

      </div>
    </div>
  );
}

export default People;