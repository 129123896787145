import React from 'react'
import { useNavigate } from 'react-router-dom';

import logo from '../icons/Atlas.png'

const LoggedIn_Header = () => {
  
    let navigate = useNavigate()
    const signOut=()=>{
        let path = '/'
        navigate(path)
        
      }
      
  return (
    <div style={{backgroundColor:'#004AAD',flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
        <img src={logo} alt="Atlas Logo" style={{height:'80px',marginLeft:'20px'}} />
        <button style={{backgroundColor:'#004AAD',fontFamily:'Roboto', fontSize:20,color:'white',height:'80px',cursor:'pointer',marginRight:'20px'}} onClick={signOut}>Log Out</button>   
    </div>
  )
}

export default LoggedIn_Header
