import React from 'react'
import { useNavigate } from 'react-router-dom';
import firebase from "../firebase";
import logo from '../icons/Atlas.png'


const Header = () => {
  
    let navigate = useNavigate()
    const signIn=()=>{  

        var g_sign=new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(g_sign).then((res)=>res.additionalUserInfo.profile.verified_email===true?verification(res):console.log('Failed'))
        
      }
      const verification=(res)=>{
        let path_true = '/all'
        let path_false = '/approval'

        firebase.app('secondary1').firestore().collection('User').get().then((snapshot)=>{
          snapshot.docs.forEach(doc=>{
            if(doc.id===res.user.email){
            if(doc.data().verified){
              navigate(path_true);
            }
            else{
              navigate(path_false);
            }
            }
          })
      })

        if(res.additionalUserInfo.isNewUser===true){
          firebase.app('secondary').firestore().collection('Users').doc(res.user.email).set({verified:false})
        }
        
      }
      
  return (
    <div style={{backgroundColor:'#004AAD',flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
        <img src={logo} alt="Atlas Logo" style={{height:'80px',marginLeft:'20px'}} />
        <button style={{backgroundColor:'#004AAD',fontFamily:'Roboto', fontSize:20,color:'white',height:'80px',cursor:'pointer',marginRight:'20px'}} onClick={signIn}>Log In</button>   
    </div>
  )
}

export default Header