import React,{useState, useRef, useEffect} from "react";
import { Link } from 'react-router-dom';

import Axios from 'axios' //used for API requests

import Spinner from '../tabs/Loader'

//used for adding OpenStreetMap using Leaflet and Maptiler
import {MapContainer, Marker, Popup, TileLayer, Tooltip} from 'react-leaflet'
import L from 'leaflet'
import osm from "../osm-providers";
import 'leaflet/dist/leaflet.css'

import "../App.css";
import LoggedIn_Header from "./LoggedIn_Header";

//import of markers
//if you need to change the icon in future change the iconUrl and add the image in the icons folder current size of image is 40*40 px

const boat_red = new L.Icon({
  iconUrl:require('../icons/boat_red.png'),
  iconSize:[40,40],
  // iconAnchor:[20,5]
})
const boat_green = new L.Icon({
  iconUrl:require('../icons/boat_green.png'),
  iconSize:[35,45],
  // iconAnchor:[20,5]
})

function Boats() {

  //center is used for setting the center point of the map and zoom level is used for zooming the map
  const [center, setCenter]=useState({lat:15.3790367,lng:73.9258356})
  const ZOOM_LEVEL = 10;
  const mapRef = useRef()

  const [boats_data,setBoats]=useState([]) //used to store boats data
  const [res_boats,setRes_boats]=useState('') //used for storing response coming from API
  const [isLoading_boats,setloading_boats]=useState(false) //used for loading till the API data is fetched
  const [check,setCheck]=useState(true) //used for the displaying of labels on map
  var count = 0 //used for storing count of active boats

  useEffect(() => {
  //fetching data from API and storing it in array
    const boats =()=>{
      Axios.get('https://aditigps.com/webservice?token=getLiveData&user=drishti&pass=Tower&company=Drishti lifesaving&format=json').then((response)=>{
        setRes_boats(response.data.root.error)
        if(response.data.root.error!==undefined){
          console.log(response)
          setloading_boats(false) 
        }
        else{
          //storing the boats in boats_data array
          console.log(response)
          setBoats(response.data.root.VehicleData)
          setloading_boats(false)
        }
      })
    }

    boats()
//fetching data from API and storing it in array after every 2 minutes
     var handle = setInterval(()=>{
      const boats =()=>{
        Axios.get('https://aditigps.com/webservice?token=getLiveData&user=drishti&pass=Tower&company=Drishti lifesaving&format=json').then((response)=>{
          setRes_boats(response.data.root.error)
          if(response.data.root.error!==undefined){
            console.log(response)  
          }
          else{
            //storing the boats in boats_data array
            console.log(response)
            setBoats(response.data.root.VehicleData)
          }
        })
      }
      boats()
     },121000)

     return()=>{
      
       clearInterval(handle)
     }
 }, []);



  return (
    <div className="container">
      <LoggedIn_Header/>
      <div className="bloc-tabs">
        <Link to="/all" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>ALL</Link>
        <Link to="/vehicle" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>VEHICLE</Link>
        <button className="tabs active-tabs" style={{fontSize:18,fontFamily:'sans-serif'}}>BOATS</button>
        <Link to="/people" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>PEOPLE</Link>
        <Link to="/triton" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>TRITON</Link>
        <Link to="/aurus" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>AURUS</Link>
        
      </div>

      <div className="content-tabs">
        <div className="content"><h2 style={{fontFamily:'Roboto'}}>Vehicle Heading</h2><hr /></div>
        
        {isLoading_boats===false?res_boats===undefined?<div className="content  active-content">

        {boats_data.map((boat)=>{if(boat.Vehicletype==="Sailboat"){
            if(boat.Status!=="INACTIVE"){
              count=count+1
            }
          }})}
          <h3 style={{fontFamily:'Roboto'}}>Active Boats: {count}</h3>
          <div style={{display:'flex', flexDirection:'row'}}>
            <h3 style={{fontFamily:'Roboto'}}>Show Boats label &nbsp;&nbsp;</h3>
            <input type="checkbox" defaultChecked onChange={(e)=>setCheck(e.target.checked)} style={{marginTop:'8px'}} />
          </div>

          <hr />

          <div style={{minHeight:'80vh'}}>
              <MapContainer
                center={center}
                zoom={ZOOM_LEVEL}
                ref={mapRef}
                style={{height:'73vh'}}
              >
                <TileLayer url={osm.maptiler.url} attribution={osm.maptiler.attribution}/>

                {/* //checking if the vehicle type is sailboat or not */}

                 {boats_data.map((city,idx) => city.Vehicletype==='Sailboat'?<Marker position={[city.Latitude,city.Longitude]} icon={city.Status==="INACTIVE"?boat_red:boat_green} key={idx}>
                 {check?<Tooltip direction="top" permanent='true'>{city.Vehicle_No+" / Speed: "+city.Speed}</Tooltip>:null}</Marker>:null)}
              </MapContainer>
          </div>
        </div> :<div className="content  active-content"><Spinner/></div>:<div className="content  active-content"><h2>Loading....</h2></div>}

        <div className="content"><h2 style={{fontFamily:'Roboto'}}>People Heading</h2><hr /></div>
        <div className="content"><h2 style={{fontFamily:'Roboto'}}>Triton Heading</h2><hr /></div>
        <div className="content"><h2 style={{fontFamily:'Roboto'}}>Aurus Heading</h2><hr /></div>
        <div className="content"><h2 style={{fontFamily:'Roboto'}}>All Heading</h2><hr /></div>

      </div>
    </div>
  );
}

export default Boats;