import React from "react"; 
import "../App.css";
import Header from '../tabs/Header'
import Screen from '../icons/Atlas_screen.png'

function Approval() {

  
  return (
    <div className="container">
      <div className="content-tabs">
      <Header/>
      <h2>Approval Required...</h2>
      <h2>Contact IT Team</h2>
         
        <div className="content  active-content"> </div>
      </div>
    </div>
  );
}

export default Approval;