import React,{useState,useRef, useEffect} from "react"; 

import { Link } from 'react-router-dom';
import "../App.css";
import L from 'leaflet'
import firebase from '../firebase'
import {MapContainer,Marker,TileLayer,Tooltip} from 'react-leaflet'
import osm from "../osm-providers";

import LoggedIn_Header from "./LoggedIn_Header";

//import of markers
//if you need to change the icon in future change the iconUrl and add the image in the icons folder current size of image is 40*40 px
const people_red = new L.Icon({
  iconUrl:require('../icons/people_red.png'),
  iconSize:[40,40],
  // iconAnchor:[20,5],
})

const people_green = new L.Icon({
  iconUrl:require('../icons/people_green.png'),
  iconSize:[40,40],
  // iconAnchor:[20,5],
})


function Triton() {

  const [center, setCenter]=useState({lat:15.3790367,lng:73.9258356}) //center is used for setting the center point of the map and zoom level is used for zooming the map
  const[ZOOM_LEVEL,setZoom]  = useState(10);
  const mapRef = useRef()
  const [triton,setTriton] = useState([]) //used to store triton data
  const [online_triton,setOnline_Triton] = useState([]) //used to store triton camera with online status
  const [loading,setLoading] = useState(true) //used for setting the loading state until data comes from firebase
  const [check,setCheck]=useState(true) //used to check if the tooltip(label) is to be shown or not

  useEffect(()=>{

  const Triton=()=>{
    online_triton.length=0
    triton.length=0
    setTriton([])
    setOnline_Triton([])
//fetching data from DWPL-Atlas firestore 'triton' collection
    const ref = firebase.app('secondary1').firestore().collection('triton')
    const data=ref.get()
    data.then((snapshot)=>{
      snapshot.docs.forEach(doc=>{
        triton.push(doc.data())
        console.log(doc.data())
        if(doc.data().camera_status==='online'){
          online_triton.push(doc.data())
        }
      })
      setTriton(triton)
      setOnline_Triton(online_triton)
      setLoading(false)
  })
  }
  Triton()

//call firestore data every 2 minutes
var handle = setInterval(()=>{


  const Triton=()=>{
    online_triton.length=0
    triton.length=0
    setTriton([])
    setOnline_Triton([])

    const ref = firebase.app('secondary1').firestore().collection('triton')
    ref.get().then((snapshot)=>{
      snapshot.docs.forEach(doc=>{
        triton.push(doc.data())
        console.log(doc.data())
        if(doc.data().camera_status==='online'){
          online_triton.push(doc.data())
        }
      })
      setTriton(triton)
      setOnline_Triton(online_triton)
      setLoading(false)
  })
  }
  Triton()

},120000)
return()=>{
  
  clearInterval(handle)
  Triton()
  console.log("Unsubscribe Triton")
}
},[])
  
  return (
    <div className="container">
      <LoggedIn_Header/>
      <div className="bloc-tabs">
        <Link to="/all" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>ALL</Link>
        <Link to="/vehicle" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>VEHICLE</Link>
        <Link to="/boats" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>BOATS</Link>
        <Link to="/people" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>PEOPLE</Link>
        <button className="tabs active-tabs" style={{fontSize:18,fontFamily:'sans-serif'}}>TRITON</button>
        <Link to="/aurus" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>AURUS</Link>
        
      </div>

      <div className="content-tabs">
        <div className="content">
          <h2 style={{fontFamily:'Roboto'}}>Vehicle Heading</h2>
          <hr />
        </div>
        

        <div className="content">
          <h2 style={{fontFamily:'Roboto'}}>Boat Heading</h2>
          <hr />
        </div>

        <div className="content">
          <h2 style={{fontFamily:'Roboto'}}>People Heading</h2>
          <hr />
        </div>

        <div className="content  active-content">
          
          <div style={{display:'flex', flexDirection:'row',justifyContent:'space-between'}}>
            <div style={{display:'flex',justifyContent:'normal',flexDirection:'column'}}>
            <h3 style={{fontFamily:'Roboto'}}>Active Poles: {online_triton.length+1}</h3>
            <div style={{display:'flex', flexDirection:'row'}}>
            <h3 style={{fontFamily:'Roboto'}}>Show Triton label &nbsp;&nbsp;</h3>
            <input type="checkbox" defaultChecked onChange={(e)=>setCheck(e.target.checked)} style={{marginTop:'8px'}} />
            </div>
           
            </div>
            </div>
          <hr />  
          {loading===false?<MapContainer
            center={center}
            zoom={ZOOM_LEVEL}
            ref={mapRef}
            style={{height:'73vh'}}
          >
            <TileLayer url={osm.maptiler.url} attribution={osm.maptiler.attribution}/>
            
            
            {/* {triton.map((triton,idx) =><Marker position={[triton.location._lat,triton.location.long]} icon={people_green} key={idx}>   
            <Tooltip direction="top" permanent='true'>{triton.name}</Tooltip></Marker> )} */}
            {triton.map((triton,idx) =><Marker position={[triton.location._lat,triton.location._long]} icon={triton.camera_status==='offline'?people_red:people_green} key={idx} >   
            {check?<Tooltip direction="top" permanent='true'>{triton.name}</Tooltip>:null}</Marker> )}
          </MapContainer>:<div>Loading...</div>}
          
          
        </div>

        <div className="content">
          <h2 style={{fontFamily:'Roboto'}}>Aurus Heading</h2>
          <hr />
          
        </div>

        <div className="content">
          <h2 style={{fontFamily:'Roboto'}}>All Heading</h2>
          <hr />
        </div>

      </div>
    </div>
  );
}

export default Triton;