import React, { Fragment } from 'react'
import Animation from '../icons/loading.mp4'

const Loader = () => {
  return (
    <Fragment>        
      <video width="100%" height="100%" autoPlay disablePictureInPicture><source src={Animation} type="video/mp4"/></video>
    </Fragment>
  )
}

export default Loader
