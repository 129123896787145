import React,{useState,useRef, useEffect} from "react"; 

import { Link } from 'react-router-dom';
import "../App.css";
import L from 'leaflet'
import firebase from '../firebase'
import {MapContainer,Marker,TileLayer,Tooltip} from 'react-leaflet'
import osm from "../osm-providers";

import LoggedIn_Header from "./LoggedIn_Header";


function Aurus() {



   return (
    <div className="container">
      <LoggedIn_Header/>
      <div className="bloc-tabs">
        <Link to="/all" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>ALL</Link>
        <Link to="/vehicle" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>VEHICLE</Link>
        <Link to="/boats" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>BOATS</Link>
        <Link to="/people" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>PEOPLE</Link>
        <Link to="/triton" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>TRITON</Link>
        <button className="tabs active-tabs" style={{fontSize:18,fontFamily:'sans-serif'}}>AURUS</button>
        
      </div>

      <div className="content-tabs">
        <div className="content"><h2 style={{fontFamily:'Roboto'}}>All Heading</h2><hr /></div> 
        <div className="content"><h2 style={{fontFamily:'Roboto'}}>Vehicle Heading</h2><hr /></div>
        <div className="content"><h2 style={{fontFamily:'Roboto'}}>Boat Heading</h2><hr /></div>
        <div className="content"><h2 style={{fontFamily:'Roboto'}}>People Heading</h2><hr /></div>
        <div className="content"><h2 style={{fontFamily:'Roboto'}}>Triton Heading</h2><hr /></div>
        
        <div className="content  active-content">
         <h4>Aurus Working......</h4>
          <hr />  
        </div>

      </div>
    </div>
  );
}

export default Aurus;