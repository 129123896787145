import React from "react";
import "./App.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Login from "./tabs/Login";
import Approval from "./tabs/Approval";
import Vehicle from "./tabs/Vehicle";
import People from "./tabs/People";
import Boats from "./tabs/Boats";
import Triton from "./tabs/Triton";
import Aurus from "./tabs/Aurus";
import All from "./tabs/All";


function App() {

  return (
    <div >
        <Router>
        <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/approval" element={<Approval/>}></Route>
        <Route path="/vehicle" element={<Vehicle />}></Route>
        <Route path="/people" element={<People />}></Route>
        <Route path="/boats" element={<Boats />}></Route>
        <Route path="/triton" element={<Triton />}></Route>
        <Route path="/aurus" element={<Aurus />}></Route>
        <Route path="/all" element={<All />}></Route>

        </Routes>
      </Router>
    </div>
  );
}
    
export default App;