//setting up firebase by adding the firbase project credentials

import firebase from 'firebase/compat/app';

import 'firebase/compat/firestore';
import 'firebase/compat/auth'

//firebase config for DLS-Field_Ops

 var config = {
  apiKey: "AIzaSyAqjLmROiUXBKcnyLQcUSfaqZkMfCv1d64",
  authDomain: "dls-field-ops.firebaseapp.com",
  databaseURL: "https://dls-field-ops.firebaseio.com",
  projectId: "dls-field-ops",
  storageBucket: "dls-field-ops.appspot.com",
  messagingSenderId: "156012628614",
  appId: "1:156012628614:web:5f07054c9beb6bc7ff1f07",
  measurementId: "G-210L743M9F"
  };

  //firebase config for Grid

   var config2 = {
    apiKey: "AIzaSyCdVSk1HaVn_djUvreinQtj2Ab3RwfbY84",
    authDomain: "grid-d6c88.firebaseapp.com",
    projectId: "grid-d6c88",
    storageBucket: "grid-d6c88.appspot.com",
    messagingSenderId: "167525793132",
    appId: "1:167525793132:web:3b147b43ce8f4bb988c749",
    measurementId: "G-PE6NR70ZHV"
  };

  //firebase config for DWPL-Atlas
  const config3 = {
    apiKey: "AIzaSyCcjP68N0ucdF4QOi4wOxWXuN9hROBRArc",  
    authDomain: "dwpl-atlas.firebaseapp.com",  
    projectId: "dwpl-atlas",  
    storageBucket: "dwpl-atlas.appspot.com",  
    messagingSenderId: "205093503363",  
    appId: "1:205093503363:web:c9d2477b875108dfe376b6",  
    measurementId: "G-1162NHZ05C"  
  };

   firebase.initializeApp(config);

   firebase.initializeApp(config2,'secondary');

   firebase.initializeApp(config3,'secondary1');

  export default firebase
   
