import React,{useState, useRef, useEffect} from "react"; 
import { Link } from 'react-router-dom';
import Axios from 'axios' //used for API requests

//used for adding OpenStreetMap using Leaflet and Maptiler
import {MapContainer, Marker, Popup, TileLayer, Tooltip} from 'react-leaflet'
import L from 'leaflet'
import osm from "../osm-providers";
import 'leaflet/dist/leaflet.css'

import "../App.css";
import LoggedIn_Header from "./LoggedIn_Header";
import Spinner from '../tabs/Loader'

//import of markers
//if you need to change the icon in future change the iconUrl and add the image in the icons folder current size of image is 40*40 px
const vehicle_red = new L.Icon({
  iconUrl:require('../icons/vehicle_red.png'),
  iconSize:[40,40],
  // iconAnchor:[20,5]
})

const vehicle_green = new L.Icon({
  iconUrl:require('../icons/vehicle_green.png'),
  iconSize:[40,40],
  // iconAnchor:[20,5]
})

function Vehicle() {

  //center is used for setting the center point of the map and zoom level is used for zooming the map
  const [center, setCenter]=useState({lat:15.3790367,lng:73.9258356})
  const ZOOM_LEVEL = 10;
  const mapRef = useRef()

  const [vehicle_data,setVehicle]=useState([]) //used to store vehicle data
  const [res,setRes]=useState('') //used for storing response coming from API
  const [isLoading,setloading]=useState(false) //used for loading till the API data is fetched
  const [check,setCheck]=useState(true) //used for the displaying of labels on map
  var count = 0 //used for storing count of active vehicles

  console.log(vehicle_data.length)

  useEffect(() => {
  //fetching data from API and storing it in array
    const vehicle =()=>{
      Axios.get('https://aditigps.com/webservice?token=getLiveData&user=drishti&pass=Tower&company=Drishti lifesaving&format=json').then((response)=>{
        setRes(response.data.root.error)
        if(response.data.root.error!==undefined){
          console.log(response)
          setloading(false)
          console.log("False")
          
        }
        else{
          console.log(response)
          setVehicle(response.data.root.VehicleData)
          setloading(false)
          console.log("True")
        }
      })
    }
    vehicle()
  //fetching data from API and storing it in array after every 2 minutes
     var handle = setInterval(()=>{
      const vehicle =()=>{
        Axios.get('https://aditigps.com/webservice?token=getLiveData&user=drishti&pass=Tower&company=Drishti lifesaving&format=json').then((response)=>{
          setRes(response.data.root.error)
          if(response.data.root.error!==undefined){
            console.log(response)  
            console.log("False")
          }
          else{
            console.log(response)
            setVehicle(response.data.root.VehicleData)
            console.log("True")
          }
        })
      }
      vehicle()
     },121000)

     return()=>{
      
       clearInterval(handle)
     }
 }, []);

  return (
    <div className="container">
      <LoggedIn_Header/>
       <div className="bloc-tabs">
        <Link to="/all" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>ALL</Link>
        <button className="tabs active-tabs" style={{fontSize:18,fontFamily:'sans-serif'}}>VEHICLE</button>
        <Link to="/boats" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>BOATS</Link>
        <Link to="/people" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>PEOPLE</Link>
        <Link to="/triton" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>TRITON</Link>
        <Link to="/aurus" className="tabs" style={{color:'black',fontFamily:'sans-serif'}}>AURUS</Link>
        
      </div>

      <div className="content-tabs">
        {isLoading===false?res===undefined?<div className="content  active-content">
          {vehicle_data.map((veh)=>{
            if(veh.Vehicletype!=="Sailboat"){
              if(veh.Status!=="INACTIVE"){
                count=count+1
              }
            }
          })}
          <h3 style={{fontFamily:'Roboto'}}>Active Vehicle: {count}</h3>
          <div style={{display:'flex', flexDirection:'row'}}>
            <h3 style={{fontFamily:'Roboto'}}>Show Vehicle label &nbsp;&nbsp;</h3>
            <input type="checkbox" defaultChecked onChange={(e)=>setCheck(e.target.checked)} style={{marginTop:'8px'}} />
          </div>
          <hr />
          <div style={{minHeight:'80vh'}}>
              <MapContainer
                center={center}
                zoom={ZOOM_LEVEL}
                ref={mapRef}
                style={{height:'73vh'}}
              >
                <TileLayer url={osm.maptiler.url} attribution={osm.maptiler.attribution}/>
                
                 {vehicle_data.map((city,idx) => city.Vehicletype!=="Sailboat"?<Marker position={[city.Latitude,city.Longitude]} icon={city.Status==="INACTIVE"?vehicle_red:vehicle_green} key={idx}>
                 {check?<Tooltip direction="top" permanent='true'>{city.Vehicle_No+" / Speed: "+city.Speed}</Tooltip>:null}</Marker>:null)}
              </MapContainer>
          </div>
        </div> :<div className="content  active-content"><Spinner/></div>:<div className="content  active-content"><h2 style={{fontFamily:'Roboto'}}>Loading....</h2></div>}
                

        <div className="content"><h2 style={{fontFamily:'Roboto'}}>Boat Heading</h2><hr /></div>
        <div className="content"><h2 style={{fontFamily:'Roboto'}}>People Heading</h2><hr /></div>
        <div className="content"><h2 style={{fontFamily:'Roboto'}}>Triton Heading</h2><hr /></div>
        <div className="content"><h2 style={{fontFamily:'Roboto'}}>Aurus Heading</h2><hr /></div>
        <div className="content"><h2 style={{fontFamily:'Roboto'}}>All Heading</h2><hr /></div>
      </div>
    </div>
  );
}

export default Vehicle;